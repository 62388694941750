@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap");

/* .landing-div {
  display: flex;
  margin-left: 420px;
  margin-right: auto;
} */

.greeting {
  /* width: 120px; */
  display: inline-block;
}

.hi {
  font-family: "Bebas Neue", cursive;
  margin: 0;
  font-size: 10rem;
  height: 120px;
  width: 120px;
  letter-spacing: 8.5px;
  -webkit-text-fill-color: #f2e9e4;
  -webkit-text-stroke: .5px black;
}

.im {
  font-family: "Bebas Neue", cursive;
  margin: 0;
  font-size: 10rem;
  width: 120px;
  /* -webkit-text-fill-color: #f2e9e4;
  -webkit-text-stroke: .5px #22223B; */
}

.jackie {
  font-family: "Bebas Neue", cursive;
  margin: 0;
  /* width: 400px;
  height: 207px; */
  /* display: inline; */
  font-size: 10rem;
  padding-left: 60px;
  letter-spacing: 1rem;
  /* -webkit-text-fill-color: #f2e9e4;
  -webkit-text-stroke: .5px #22223B; */
}

.name-description {
  /* width: 120px; */
  display: inline-block;
}

.description {
  font-family: "Bebas Neue", cursive;
  margin: 0;
  padding-left: 50px;
  font-size: 4rem;
  width: 450px;
  color: whitesmoke;
  text-shadow: rgba(128, 128, 128, 0.632) 2px 2px;

  /* -webkit-text-fill-color: white; */
  -webkit-text-stroke: .5px rgba(128, 128, 128, 0.632);
}

@media only screen and (min-device-width: 300px) and (max-device-width: 480px) {

/* .landing {
  display: inline-block;
} */

.greeting {
  width: 70px;
}

.hi {
  font-size: 5rem;
  height: 70px;
  width: 70px;
  letter-spacing: 3.5px;
  -webkit-text-stroke: 2px black;
}

.im {
  font-size: 5rem;
  height: 70px;
  width: 70px;
}

.jackie {
  display: inline-block;
  font-size: 5rem;
  padding-left: 30px !important;
  letter-spacing: normal;
  width: 170px;
}

.description {
  font-size: 1.5rem;
  width: auto;
  padding-left: 50px !important;
}
}

/* .landing {
  position: absolute;
}

.name {
  position:relative;
}
.flower {
  position: absolute;
  height: 500px;
} */
