@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap");

.connect {
  font-family: "Bebas Neue", cursive;
  font-size: 60px;
  letter-spacing: 15px;
  text-shadow: rgba(128, 128, 128, 0.632) 2px 2px;
}

.external-link-email{
  color: black;
  padding: 50px;

}

.external-link-linkedin{
  color: black;
  padding: 50px;

}

.external-link-github{
  color: black;
  padding: 50px;

}

.MuiSvgIcon-root {
  transform: scale(2);
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
.connect {
  letter-spacing: 2.5px;
}

.external-link-email{
  color: black;
  padding: 25px;

}

.external-link-linkedin{
  color: black;
  padding: 25px;

}

.external-link-github{
  color: black;
  padding: 25px;

}
}