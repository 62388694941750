.about-image {
  /* position: relative; */
  display: flex;
  justify-content: center;
  padding: 100px;
  /* margin-bottom: 80px; */
}

.about {
  /* position: absolute; */
  width: 70%;
  display: inline-block;
  border: solid black .1px;
  border-radius: 12px;
  background-color: rgba(255, 255, 255, 0.632);
  backdrop-filter: blur(5px);
  text-align: right;
}

.about-detail {
  padding: 0 17px;
  font-size: 20px;
  text-align: justify;
  margin: 20px 0 10px 0;

}

.resume {
  padding-right: 17px;
  color: black;
  font-weight: bold;
}

.img-me {
  height: 300px;
  border: solid black .1px;
  /* border-bottom: 0px; */
  border-radius: 10px;
}

@media only screen and (min-device-width: 300px) and (max-device-width: 480px) {
  .about-image {
    display:contents;
  }

  .about {
    width: 300px;
    border: none;
  }

  .about-detail {
    margin: 10px 0 10px 0;
  }

  .img-me {
    width: 300px;
    height: auto;
    margin-top: 50px;
    border: none;
  }
}
