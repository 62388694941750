@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300&family=Mrs+Saint+Delafield&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Fira+Code:wght@300&family=IM+Fell+English&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Abel&display=swap');

.blob-box {
  display: flex;
  /* padding-top: 100px; */
  /* background-color: #5f6588; */
}

text {
  font-size: 7.5px;
}

.name-1 {
  font-family: 'Mrs Saint Delafield', cursive;
  font-size: 20px;
}

.link-1 {
  font-size: x-small;
}

.github-1 {
  font-size: x-small;
}

.name-2 {
  font-family: 'IM Fell English', serif;
  font-size: 18px;
}

.link-2 {
  font-size: x-small;
}

.github-2 {
  font-size: x-small;
}

.name-3{
  font-family: 'Abel', sans-serif;
  font-size: 15px;
}

.link-3 {
  font-size: x-small;
}

.github-3 {
  font-size: x-small;
}

.test {
  size: 20px;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .blob-box {
    display: block;
    padding-top: 50px;
  }
}