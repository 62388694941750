@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100&display=swap');

.App {
  text-align: center;
  background-color: #F2E9E4 !important;
  overflow: hidden;
  font-family: 'Lato', sans-serif;
}


